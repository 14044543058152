import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';

import './Styles/Index.css'
import { AppProvider } from './Context';
import ErrorBoundary from './Routes/ErrorBoundary';
import Constants from './Config/Constants.json';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <BrowserRouter basename={Constants.BASE_PATH}>
            <ErrorBoundary>

                <AppProvider>
                    <App />
                </AppProvider>
            </ErrorBoundary>

        </BrowserRouter>
        
    </React.StrictMode>
);
