import React from 'react';

import Constants from "../Config/Constants.json"


const GetDeviceLanguage = () => {
        
    const locales = navigator.languages ? navigator.languages : [navigator.language];
    
    // Extract the language code (e.g., 'en' for English)
    const language = locales[0].split('-')[0]; // Get the language code only
    
    // Check if the language is RTL (right-to-left)
    const isRTL = Constants.RTL_LANGUAGES.includes(language);

    return { language, isRTL };
}

export default GetDeviceLanguage;
