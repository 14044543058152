import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI
        return { hasError: true, error };
    }

    componentDidCatch(error, errorInfo) {
        // Log error to an error reporting service if needed
        //console.error("Error caught in Error Boundary:", error, errorInfo);
        this.setState({ error });
    }

    componentDidUpdate() {
        if (this.state.hasError) {
            // Use navigate to redirect and pass error information
            this.props.navigate('/error', { state: { error: this.state.error } });
        }
    }

    render() {
        if (this.state.hasError) {
            return null; // Render nothing, as we are redirecting
        }

        return this.props.children;
    }
}

// Wrapper component to pass navigate function to ErrorBoundary
const ErrorBoundaryWithNavigate = (props) => {
    const navigate = useNavigate();

    return <ErrorBoundary {...props} navigate={navigate} />;
};

export default ErrorBoundaryWithNavigate;
