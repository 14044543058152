import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { FaExclamationTriangle } from 'react-icons/fa'; // Import the icon from react-icons
import Header from '../Components/Base/Header';
import { useLocation } from 'react-router-dom';
import { useAppContext } from '../Context';

// Styled components
const ErrorPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(135deg, #ffffff, #fdfdfd); /* Very bright gradient */
    text-align: center;
    font-family: 'Roboto', sans-serif;
    margin: 50px 0px 20px;
`;

const ErrorHeader = styled.h1`
    font-size: 24px; /* Smaller font size for the error header */
    color: #d9534f; /* Red color for error */
`;

const ErrorMessage = styled.p`
    font-size: 24px; /* Smaller font size for the error message */
    color: #555; /* Dark gray for the message */
    max-width: 600px; /* Limit the width */
`;

const ErrorIcon = styled.div`
    font-size: 60px; /* Smaller icon size */
    color: #d9534f; /* Matching color with header */
`;

const Error = ({ errorCode }) => {
    const location = useLocation();
    const { error } = location.state || {}; // Extract the error from location state

    const { t, i18n } = useTranslation();

    const { 
        language, setLanguage,
        languageDirection, setLanguageDirection,
        
    } = useAppContext();
     
    const getErrorMessage = (code) => {
        switch (code) {
            case 400:
                return t("Bad Request: The server could not understand the request.");
            case 401:
                return t("Unauthorized: Access is denied due to invalid credentials.");
            case 402:
                return t("Payment Required: Payment is required to access the resource.");
            case 403:
                return t("Forbidden: You do not have permission to access this resource.");
            case 404:
                return t("Not Found: The requested resource could not be found.");
            case 405:
                return t("Method Not Allowed: The method specified in the request is not allowed.");
            case 408:
                return t("Request Timeout: The server timed out waiting for the request.");
            case 409:
                return t("Conflict: The request could not be completed due to a conflict.");
            case 410:
                return t("Gone: The resource is no longer available.");
            case 429:
                return t("Too Many Requests: You have sent too many requests in a given amount of time.");
            case 500:
                return t("Internal Server Error: The server encountered an error.");
            case 501:
                return t("Not Implemented: The server does not recognize the request method.");
            case 502:
                return t("Bad Gateway: The server received an invalid response from the upstream server.");
            case 503:
                return t("Service Unavailable: The server is not ready to handle the request.");
            case 504:
                return t("Gateway Timeout: The server, acting as a gateway, timed out waiting for an upstream server.");
            default:
                return t("An unexpected error has occurred.");
        }
    };        

    return (
        <>
            <Header />
            
            <ErrorPageContainer>
                <ErrorIcon>
                    <FaExclamationTriangle /> {/* Error icon */}
                </ErrorIcon>
                {
                    error?
                    <ErrorMessage dir={languageDirection}>{error.toString()}</ErrorMessage>
                    :
                    <ErrorMessage dir={languageDirection}>{getErrorMessage(errorCode)}</ErrorMessage>
                }
                <ErrorHeader dir={languageDirection}>{t("Error")} {errorCode}</ErrorHeader>

            </ErrorPageContainer>
        </>
    );
};

Error.propTypes = {
    errorCode: PropTypes.number.isRequired,
};

export default Error;
