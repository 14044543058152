import { createContext, useContext, useState } from "react";

export const AppContext = createContext();

export function useAppContext() {
	return useContext(AppContext);
}

export function AppProvider({ children }) {
    
    const [isConnected, setIsConnected] = useState(false)

    const [isAuthenticated, setIsAuthenticated] = useState(false)

    const [language, setLanguage] = useState('')
    const [languageDirection, setLanguageDirection] = useState('ltr')


    const [accountId, setAccountId] = useState('')
    const [accountEmail, setAccountEmail] = useState('')
    const [accountUserName, setAccountUserName] = useState('')
    const [accountFullName, setAccountFullName] = useState('')
    const [accountSex, setAccountSex] = useState('')
    const [accountBirthDate, setAccountBirthDate] = useState('')
    const [accountPhoneNumber, setAccountPhoneNumber] = useState('')

    const [accountIsDefaultIcon, setAccountIsDefaultIcon] = useState(true)
    const [accountIcon, setAccountIcon] = useState('')

    const [accountLocationName, setAccountLocationName] = useState('')
    const [accountLocationLatitude, setAccountLocationLatitude] = useState(0)
    const [accountLocationLongitude, setAccountLocationLongitude] = useState(0)

    const resetAppContext = () => {
       
        setIsAuthenticated(false)

        setAccountId('')
        setAccountEmail('')
        setAccountUserName('')
        setAccountFullName('')
        setAccountSex('')
        setAccountBirthDate('')
        setAccountPhoneNumber('')

        setAccountIsDefaultIcon('')
        setAccountIcon('')
        
        setAccountLocationName('')
        setAccountLocationLatitude(0)
        setAccountLocationLongitude(0)

    }

    
    return (
        <AppContext.Provider value={{ 

            isConnected, setIsConnected,

            isAuthenticated, setIsAuthenticated,

            language, setLanguage,
            languageDirection, setLanguageDirection,
            
            accountId, setAccountId,
            accountEmail, setAccountEmail,
            accountUserName, setAccountUserName,
            accountFullName, setAccountFullName,
            accountSex, setAccountSex,
            accountBirthDate, setAccountBirthDate,
            accountPhoneNumber, setAccountPhoneNumber,

            accountIsDefaultIcon, setAccountIsDefaultIcon,
            accountIcon, setAccountIcon,

            accountLocationName, setAccountLocationName,
            accountLocationLatitude, setAccountLocationLatitude,
            accountLocationLongitude, setAccountLocationLongitude,

            resetAppContext,
        }}>

		    {children}

		</AppContext.Provider>
	);
}
