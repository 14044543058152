import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './Locales/en.json';
import fr from './Locales/fr.json';
import ar from './Locales/ar.json';

import Constants from './Config/Constants.json';

// Detect the user's language preference
const locales = navigator.languages ? navigator.languages : [navigator.language];

const lang = locales[0].split('-')[0]
if (!Constants.SUPPORTED_LANGUAGES.includes(lang)) {
    lang = 'en'
}

//const isRTL = locales[0] && Constants.RTL_LANGUAGES.includes(locales[0].languageCode);

i18n
.use(initReactI18next) // passes i18n down to react-i18next
.init({
    compatibilityJSON: 'v3',
    fallbackLng: 'en',
    lng: lang,
    debug: false,
    resources: {
        en: {
            translation: en,
        },
        fr: {
            translation: fr,
        },
        ar: {
            translation: ar,
        },
    },
    interpolation: {
        escapeValue: false, // react already safes from xss
    },
});

export default i18n;
