import React, { useEffect } from 'react';

import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';

import { I18nextProvider, useTranslation } from 'react-i18next';
import i18n from './I18n';

import Main from "./Main";

import Constants from './Config/Constants'

import { AppProvider, useAppContext } from './Context'

import GetDeviceLanguage from './Functions/GetDeviceLanguage';

import './Styles/MainStyles.css';


function App() {
    const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();
    const navigate = useNavigate();
    
    const { 
        language, setLanguage,
        languageDirection, setLanguageDirection,

    } = useAppContext();


    useEffect(() => {

        // Get the 'lang' parameter from the URL
        const langParam = searchParams.get('lang');

        var lang = langParam || language || GetDeviceLanguage().language || 'en'

        if (!Constants.SUPPORTED_LANGUAGES.includes(lang)) {
            lang = 'en'
        }

        const isRTL = Constants.RTL_LANGUAGES.includes(lang);

        setLanguage(lang)
        setLanguageDirection(isRTL?'rtl':'ltr')
        i18n.changeLanguage(lang);

        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.set('lang', lang);

        // Update the URL with the new parameters
        //setSearchParams(newSearchParams);
        navigate(`${location.pathname}?${newSearchParams.toString()}`, { replace: true });
        
    }, [searchParams]); // React to changes in the query string

    return (
        <I18nextProvider i18n={i18n}>
			<Main />
		</I18nextProvider>
    );
}

export default App;
