import { Route, Routes } from 'react-router-dom';

import Error from './Routes/Error';

import Base from './Routes/Base';
import ErrorBoundary from './Routes/ErrorBoundary';
import ScrollToTop from './Components/Base/ScrollToTop';


const Main = () => {
    
	return (
        <>
            <Routes>
                <Route exact path="/" element={<Base/>} />

                <Route path="/error" element={<Error />}  />
                <Route path="*" element={<Error errorCode={404} />}  />
            </Routes>

            <ScrollToTop /> 

        </>

    )
}

export default Main;