import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { AiOutlineHome } from 'react-icons/ai'; // Outlined Home icon
import { MdLanguage } from 'react-icons/md'; // Language icon
import icon from '../../Assets/Icons/icon.png';
import Constants from '../../Config/Constants';
import '@fontsource/roboto'; // Import Roboto font from fontsource
import { useAppContext } from '../../Context';
import { useTranslation } from 'react-i18next';

const HeaderContainer = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px 10px;
    background-color: white; /* White background */
    border-bottom: 1px solid #ddd;
`;

const HeaderLeft = styled.div`
    display: flex;
    align-items: center;
`;

const Logo = styled.img`
    width: 40px;
    height: 40px;
    margin-right: 10px;
`;

const Title = styled.h1`
    font-size: 22px; /* Slightly smaller size */
    color: #0078a6; /* Modern gray color */
    font-family: 'Poppins', sans-serif; /* Changed font to Poppins */
    letter-spacing: 0px; /* Tighter letter spacing */
    margin: 0;
`;

const HeaderRight = styled.div`
    display: flex;
    align-items: center;
`;

const IconButton = styled.button`
    -webkit-tap-highlight-color: transparent;
    background: none; /* No background */
    border: none; /* No border */
    cursor: pointer;
    margin-right: 15px;
    color: #444; /* Icon color */
    font-size: 24px; /* Icon size */
    transition: color 0.3s;

    &:active {
        background: none;
        border: none;
        outline: none;
        box-shadow: none;
        color: inherit;
        text-shadow: none;
    }

    &:hover {
        color: #222; /* Darker color on hover */
    }

    &:focus {
        outline: none; /* Remove focus outline */
    }
`;

const LanguageContainer = styled.div`
    position: relative;
`;

const LanguageButton = styled.button`
    -webkit-tap-highlight-color: transparent;
    background-color: #e6f7ff; /* Cooler background color */
    color: #555; /* Text color */
    border: none; /* No border */
    border-radius: 20px; /* Rounded corners */
    padding: 8px 15px; /* Adjusted padding */
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 18px; /* Bigger font size */
    transition: none; /* No transition effect */

    &:hover {
        color: #555; /* Keep text color same on hover */
    }

    &:focus {
        outline: none; /* Remove focus outline */
    }
`;

const LanguagePopup = styled.div`
    position: absolute;
    top: 45px; /* Adjusted space above the popup */
    right: 0;
    background: #f8f9fa; /* Popup background color */
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
`;

const LanguageList = styled.ul`
    list-style-type: none;
    padding: 10px;
    margin: 0;
`;

const LanguageItem = styled.li`
    padding: 8px 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: background-color 0.3s;

    &:hover {
        background-color: #e2e6ea; /* Background color on hover */
    }
`;

const Checkmark = styled.span`
    margin-right: 10px;
    color: black; /* Black checkmark */
    font-weight: bold;

    /* Make the checkmark invisible for unselected languages */
    visibility: ${props => (props.visible ? 'visible' : 'hidden')};
`;

const Header = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();
    const navigate = useNavigate();

    const { t, i18n } = useTranslation();

    const { 
        language, setLanguage,
        languageDirection, setLanguageDirection,
        
    } = useAppContext();

    const [isLanguagePopupVisible, setLanguagePopupVisible] = useState(false);
    const popupRef = useRef(null);

    const handleLanguageChange = (lang) => {
        
        const isRTL = Constants.RTL_LANGUAGES.includes(lang);

        setLanguage(lang)
        setLanguageDirection(isRTL?'rtl':'ltr')

        i18n.changeLanguage(lang);

        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.set('lang', lang);

        // Update the URL with the new parameters
        //setSearchParams(newSearchParams);
        navigate(`${location.pathname}?${newSearchParams.toString()}`, { replace: true });
        
        setLanguagePopupVisible(false);
    };

    const toggleLanguagePopup = () => {
        setLanguagePopupVisible(!isLanguagePopupVisible);
    };

    const handleClickOutside = (event) => {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
            setLanguagePopupVisible(false);
        }
    };

    useEffect(() => {
        // Listen for clicks outside the popup
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <HeaderContainer>
            <HeaderLeft>
                <Logo src={icon} alt="Logo" />
                <Title>{Constants.APP_NAME}</Title>
            </HeaderLeft>
            <HeaderRight>
                {location.pathname !== '/' && ( // Show home icon only if not on home page ?lang=${language}
                    <IconButton onClick={() => navigate(`/`)}>
                        <AiOutlineHome /> {/* Outlined Home icon */}
                    </IconButton>
                )}
                <LanguageContainer ref={popupRef}>
                    <LanguageButton onClick={toggleLanguagePopup}>
                        <MdLanguage style={{ marginRight: '5px' }} /> {/* Language icon */}
                        {language === 'en' && 'English'}
                        {language === 'fr' && 'Français'}
                        {language === 'ar' && 'العربية'}
                    </LanguageButton>
                    {isLanguagePopupVisible && (
                        <LanguagePopup>
                            <LanguageList>
                                {['en', 'fr', 'ar'].map((lang) => (
                                    <LanguageItem key={lang} onClick={() => handleLanguageChange(lang)}>
                                        <Checkmark visible={language === lang}>✓</Checkmark> {/* Custom checkmark */}
                                        {lang === 'en' && 'English'}
                                        {lang === 'fr' && 'Français'}
                                        {lang === 'ar' && 'العربية'}
                                    </LanguageItem>
                                ))}
                            </LanguageList>
                        </LanguagePopup>
                    )}
                </LanguageContainer>
            </HeaderRight>
        </HeaderContainer>
    );
};

export default Header;
