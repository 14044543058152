import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

// Styled component with your design
const ScrollButton = styled.button`
    -webkit-tap-highlight-color: transparent;
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: blue;
    padding: 10px;
    border-radius: 20px;
    elevation: 5; /* Shadow for Android */
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.5); /* Shadow for iOS */
    z-index: 6;
    border: none;
    cursor: pointer;
    color: white;
    font-weight: bold;
    font-size: 16px;
    display: ${({ visible }) => (visible ? 'inline' : 'none')}; /* Show only when visible */
`;

const ScrollToTop = () => {
    const [isVisible, setIsVisible] = useState(false);

    const { t, i18n } = useTranslation();
    
    // Show or hide the button depending on scroll position
    const toggleVisibility = () => {
        if (window.pageYOffset > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    // Scroll the window to the top smoothly
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);

        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    return (
        <ScrollButton onClick={scrollToTop} visible={isVisible}>
            ↑ {t("Top")}
        </ScrollButton>
    );
};

export default ScrollToTop;
